import React from 'react';
import PropTypes from 'prop-types';
import './service-list.scss';
import { mergeClasses } from '../../../libs/helpers';

const ServicesList = (props) => {
  const { className, items, title } = props;

  const ROOT_CLASS = 'service-list';
  const TITLE_CLASS = 'service-list__title page__title page__title_h2';
  const ITEM_CLASS = 'service-list__item';
  const TITLE_ITEM_CLASS = 'service-list__item-title page__title page__title_h6';
  const rootClass = mergeClasses(ROOT_CLASS, className);

  return (
    <ul className={rootClass}>
      <div className={TITLE_CLASS}>{title}</div>
      {items && items.map((item, key) => (
        <li className={ITEM_CLASS} key={key}>
          <div className={TITLE_ITEM_CLASS}>{item.title}</div>
        </li>
      ))}
    </ul>
  );
};

ServicesList.propTypes = {
  className: PropTypes.string,
  items: PropTypes.array,
  title: PropTypes.string,
};

ServicesList.defaultProps = {
  className: '',
  items: [],
  title: '',
};

export default ServicesList;
