import React from 'react';
import StandardPage from '../components/page/page-standard';
import '../components/service-list-page/service-list-page.scss';
import services from '../__mocks__/servicesList';
import ServicesList from '../components/service-list/service-list';

const pageName = 'service-list-page';

const ServicesListPage = () => {
  const LIST_CLASS = 'service-list-page__list';

  return (
    <StandardPage pageName={pageName} title="Список услуг">
      {services.map((service, key) => (
        // eslint-disable-next-line react/jsx-props-no-spreading,react/no-array-index-key
        <ServicesList className={LIST_CLASS} key={key} {...service} />
      ))}
    </StandardPage>
  );
};

export default ServicesListPage;
