// todo alex запонить данные актуальными и полностью

export default [
  {
    title: 'Основные услуги',
    items: [
      {
        title: 'Снятие зубного камня/налёта',
      },
      {
        title: 'Чистка клыков пастой',
      },
      {
        title: 'Ультразвуковая чистка клыков',
      },
      {
        title: 'Лапакюр',
      },
      {
        title: 'Гигиена',
      },
      {
        title: 'Вычёсывание колтунов',
      },
      {
        title: 'Антипаразитарный комплекс',
      },
      {
        title: 'Снятие клеща',
      },
      {
        title: 'Обработка раны',
      },
      {
        title: 'Купание',
      },
      {
        title: 'Сушка',
      },
    ],
  },
  {
    title: 'Креатив',
    items: [
      {
        title: 'Плетение косичек',
      },
      {
        title: 'Блеск Тату',
      },
      {
        title: 'Покрытие когтей лаком',
      },
      {
        title: 'Декор стразами',
      },
    ],
  },
  {
    title: 'Креативная стрижка',
    items: [
      {
        title: 'Шиншила',
      },
      {
        title: 'Ананас',
      },
    ],
  },
  {
    title: 'Креативное окрашивание',
    items: [
      {
        title: 'Тигр и Леопард',
      },
      {
        title: 'Медвежонок панда',
      },
      {
        title: 'Тигр и Леопард',
      },
      {
        title: 'Медвежонок панда',
      },
    ],
  },
];
